import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { TRAINING_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import { deleteTrainingAction } from "../../../redux/actions/trainingPaginationAction";
import DeleteItemModal from "../../../globalComponents/Modals/DeleteItemModal/DeleteItemModal";
import UpdateDeleteModal from "../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";

const TrainingCard = ({ data, cellNumber, mode }) => {
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  let teachers =
    Array.isArray(data?.teachers) && data?.teachers.length > 0
      ? data?.teachers
          .map((item) => {
            return `${item.fullName}`;
          })
          .join(", ")
      : "";

  const updateItem = () => {
    console.log(data);
    dispatch({
      type: TRAINING_MODAL_ACTION_TYPE.GET_TRAINING_MODAL,
      payload: {
        data: data,
        openModal: true,
      },
    });
  };
  const deleteItem = () => {
    dispatch(deleteTrainingAction(data._id));
  };

  const doubleClick = () => {
    updateItem("");
  };

  return (
    <>
      {showDeleteModal && (
        <DeleteItemModal
          setShowDeleteModal={setShowDeleteModal}
          deleteItem={deleteItem}
        />
      )}

      <tr className="class-table" onDoubleClick={doubleClick}>
        <td>
          <div className="td-con">
            <div className="table-scroll-text">
              {cellNumber}. {data.name}
            </div>
            <div className="right-fade"></div>
          </div>
        </td>
        <td>
          <div className="td-con">
            <div className="table-scroll-text">{teachers}</div>
            <div className="right-fade"></div>
          </div>
        </td>

        <td>
          <UpdateDeleteModal
            updateItem={updateItem}
            deleteItem={deleteItem}
            profil={"training"}
            setShowDeleteModal={setShowDeleteModal}
            data={data}
          />
        </td>
      </tr>
    </>
  );
};

export default TrainingCard;
