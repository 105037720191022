import { useSelector } from "react-redux";
import LoadingBtn from "../../Loading/components/LoadingBtn/LoadingBtn";
import "./deleteItemModal.css";

const DeleteItemModal = ({ setShowDeleteModal, deleteItem }) => {
  const { deleteModalLoading } = useSelector((state) => state.deleteModal);

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <div className="delete-modal">
      <div className="delete-container">
        <p>Silmək istədiyinizə əminsiniz?</p>
        <div className="modal-btn">
          <button className="cancel-btn" onClick={() => closeModal(false)}>
            Ləğv et
          </button>
          <button className="delete-btn" onClick={deleteItem}>
            {(deleteModalLoading && <LoadingBtn />) || "Sil"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteItemModal;
