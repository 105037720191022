import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import { ReactComponent as CloseBtn } from "../../../assets/icons/Icon.svg";
import { Box } from "@mui/material";
import { STUDENTS_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import SubmitBtn from "./components/Buttons/SubmitBtn";
import InputField from "./components/Inputs/InputField";
import WhereComing from "./components/SelectCollection/WhereComing";
import GroupList from "./components/Groups/GroupList";
import { useValidationSchema } from "./components/ValidationSchema/useValidationSchema";
import Status from "./components/Groups/components/Status/Status";
import PaymentsInputField from "./components/Inputs/PaymentsInputField";
import Payments from "./components/Groups/components/Payments/Payments";

export const StudentModal = () => {
  const dispatch = useDispatch();
  const { studentsModalData: modalData } = useSelector(
    (state) => state.studentsModal
  );
  const inputNameArr1 = ["email", "password"];

  const inputNameArr2 = ["school", "phone"];

  // formik
  const formik = useFormik({
    initialValues: {
      fullName: modalData.fullName ? modalData.fullName : "",
    },
    validationSchema: useValidationSchema(),
  });

  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  const addPayments = (key, value, index) => {
    const newPayment = { ...modalData.payments[index], [key]: value };
    const newPayments = modalData.payments.map((payment, i) =>
      index === i ? newPayment : payment
    );

    updateModalState("payments", newPayments);
  };

  const updateModalState = (keyName, value) => {
    setInputValue(keyName, value);

    dispatch({
      type: STUDENTS_MODAL_ACTION_TYPE.GET_STUDENTS_MODAL,
      payload: {
        data: { ...modalData, [keyName]: value },
        openModal: true,
      },
    });
  };

  const closeModal = () => {
    dispatch({
      type: STUDENTS_MODAL_ACTION_TYPE.GET_STUDENTS_MODAL,
      payload: { data: {}, openModal: false },
    });
  };

  useEffect(() => {
    if (modalData.amount && modalData.paymentPart) {
      const amount = modalData.amount;
      const discount = modalData?.discount || 0;

      const totalAmount = amount - (amount * discount) / 100;
      const part = modalData.paymentPart;
      const onePartPayment = totalAmount / part;

      const paymentArr = [];

      for (let i = 0; i < part; i++) {
        paymentArr.push({
          payment: onePartPayment.toFixed(2),
          paymentDate: "",
        });
      }

      // const updatedGroupData = {
      //   ...groupData,
      //   payments: paymentArr,
      //   totalAmount: totalAmount,
      // };
      updateModalState("payments", paymentArr);
    }
  }, [modalData.discount, modalData.amount, modalData.paymentPart]);

  console.log(modalData, "modalData Student");
  return (
    <div className="create-update-modal-con student-modal">
      <div className="create-update-modal">
        <div className="create-update-modal-head">
          <h2>{modalData?._id ? "Tələbə yenilə" : "Tələbə yarat"}</h2>
          <CloseBtn onClick={closeModal} />
        </div>

        <Box
          onSubmit={(e) => e.preventDefault()}
          component="form"
          sx={{
            width: 500,
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="create-update-modal-form">
            {modalData?._id && (
              <Status
                setInputValue={setInputValue}
                modalData={modalData}
                formik={formik}
                updateModalState={updateModalState}
              />
            )}
            <InputField
              inputName={"fullName"}
              formik={formik}
              setInputValue={setInputValue}
              modalData={modalData}
              updateModalState={updateModalState}
            />
            <div className="input-couples">
              {inputNameArr1.map((name, index) => (
                <InputField
                  key={index}
                  inputName={name}
                  formik={formik}
                  setInputValue={setInputValue}
                  modalData={modalData}
                  updateModalState={updateModalState}
                />
              ))}
            </div>
            <div className="input-couples birthday ">
              {inputNameArr2.map((name, index) => (
                <InputField
                  key={index}
                  inputName={name}
                  formik={formik}
                  setInputValue={setInputValue}
                  modalData={modalData}
                  updateModalState={updateModalState}
                />
              ))}
            </div>

            <WhereComing
              modalData={modalData}
              updateModalState={updateModalState}
              formik={formik}
            />

            <GroupList
              formik={formik}
              setInputValue={setInputValue}
              updateModalState={updateModalState}
              modalData={modalData}
            />

            <PaymentsInputField
              inputName={"contractStartDate"}
              formik={formik}
              setInputValue={setInputValue}
              data={modalData}
              updateModalState={updateModalState}
            />
            <PaymentsInputField
              inputName={"contractEndDate"}
              formik={formik}
              setInputValue={setInputValue}
              data={modalData}
              updateModalState={updateModalState}
            />

            <div className="input-couples">
              {/* <DiscountReason
              data={data}
              addGroupData={addGroupData}
              formik={formik}
            /> */}
              <PaymentsInputField
                inputName={"discount"}
                formik={formik}
                setInputValue={setInputValue}
                data={modalData}
                updateModalState={updateModalState}
              />
              <PaymentsInputField
                inputName={"amount"}
                formik={formik}
                setInputValue={setInputValue}
                data={modalData}
                updateModalState={updateModalState}
              />
              <PaymentsInputField
                inputName={"totalAmount"}
                formik={formik}
                setInputValue={setInputValue}
                data={modalData}
                updateModalState={updateModalState}
              />
              <PaymentsInputField
                inputName={"paymentPart"}
                formik={formik}
                setInputValue={setInputValue}
                data={modalData}
                updateModalState={updateModalState}
              />
            </div>
            <h2 style={{ marginTop: "20px" }}>Ödənişlər</h2>
            {modalData?.payments?.map((item, index) => (
              <Payments
                key={index}
                index={index}
                setInputValue={setInputValue}
                data={item}
                addPayments={addPayments}
                formik={formik}
              />
            ))}
          </div>
        </Box>

        <SubmitBtn
          formik={formik}
          modalData={modalData}
          closeModal={closeModal}
          funcType={modalData?._id ? "update" : "create"}
        />
      </div>
    </div>
  );
};
