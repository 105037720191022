import React from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createTrainingAction,
  updateTrainingAction,
} from "../../../../../redux/actions/trainingPaginationAction";
import LoadingBtn from "../../../../Loading/components/LoadingBtn/LoadingBtn";

export default function SubmitBtn({ formik, modalData, funcType }) {
  const dispatch = useDispatch();
  const { trainingModalLoading } = useSelector((state) => state.trainingModal);
  const classCreate = () => {
    if (modalData?._id) {
      dispatch(updateTrainingAction(modalData?._id, modalData));
    } else {
      dispatch(createTrainingAction(modalData));
    }
  };

  console.log(trainingModalLoading, "trainingModalLoading");
  return (
    <div className="create-update-modal-btn">
      <button
        disabled={!(formik.isValid && modalData?.name && !trainingModalLoading)}
        onClick={classCreate}
      >
        {trainingModalLoading ? (
          <LoadingBtn />
        ) : funcType === "update" ? (
          "Yenilə"
        ) : (
          "Yarat"
        )}
      </button>
    </div>
  );
}
