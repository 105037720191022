import axios from "axios";
import { apiRoot } from "../../apiRoot";
import {
  SALARY_MODAL_ACTION_TYPE,
  SALARY_PAGINATION_ACTION_TYPE,
} from "../actions-type";

const refreshApi = axios.create({
  baseURL: `${apiRoot}/user/auth/refresh_token`,
  withCredentials: true,
});

const API = axios.create({
  baseURL: `${apiRoot}/salary`,
  withCredentials: true,
});
const REGISTERAPI = axios.create({
  baseURL: `${apiRoot}/user/auth`,
  withCredentials: true,
});

API.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

REGISTERAPI.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

const setLoadingSalaryAction = (loadingValue) => ({
  type: SALARY_PAGINATION_ACTION_TYPE.SALARY_LOADING,
  payload: loadingValue,
});

const salaryModalLoading = (loadingValue) => ({
  type: SALARY_MODAL_ACTION_TYPE.SALARY_MODAL_LOADING,
  payload: loadingValue,
});

export const getSalaryPaginationAction =
  (length, searchQuery, startDate, endDate) => async (dispatch) => {
    dispatch(setLoadingSalaryAction(true));
    try {
      const { data } = await API.get(
        `/?length=${length}&searchQuery=${searchQuery || ""}&startDate=${
          startDate || ""
        }&endDate=${endDate || ""}`
      );
      dispatch({
        type: SALARY_PAGINATION_ACTION_TYPE.GET_SALARY_PAGINATION,
        payload: data,
      });
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setLoadingSalaryAction(false));
    }
  };

export const updateSalaryAction = (_id, newData) => async (dispatch) => {
  try {
    const { data } = await API.patch(`/${_id}/payment`, newData);
    dispatch({
      type: SALARY_MODAL_ACTION_TYPE.UPDATE_SALARY_PAYMENTS,
      payload: {
        data: data,
        openModal: false,
        confirmModal: true,
      },
    });
  } catch (error) {}
};

export const confirmSalaryAction = (newData) => async (dispatch) => {
  dispatch(
    salaryModalLoading({ teacherId: newData.teacher._id, loading: true })
  );
  try {
    const { data } = await API.post("/confirm", newData);
    dispatch({
      type: SALARY_PAGINATION_ACTION_TYPE.CONFIRM_SALARY,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(salaryModalLoading({ teacherId: null, loading: false }));
  }
};
