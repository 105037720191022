import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { getGroupsAction } from "../../../../../redux/actions/groupsActions";
import GroupInput from "./GroupInput";
import DropdownIcon from "../../../components/DropdownIcon/DropdownIcon";

const GroupList = ({ modalData, updateModalState, formik, setInputValue }) => {
  const { groupData } = useSelector((state) => state.groupsPagination);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [selectedGroup, setSelectedProgram] = useState({});
  const inputValue = modalData ? selectedGroup?.name : "";
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getGroupsAction("all"));
  }, [dispatch]);

  const addData = (item) => {
    updateModalState("group", item);
    setOpenDropdown(false);
    setSelectedProgram(item);
  };

  return (
    <div>
      <div className="class-input">
        <div className="dropdown-input">
          <div className="input-box">
            <TextField
              sx={{
                "& input": {
                  fontSize: "12px",
                  marginRight: "32px",
                },
                marginTop: "24px",
                // marginBottom: "24px",
              }}
              InputLabelProps={{
                style: { fontSize: "12px", color: "#3F3F3F" },
              }}
              fullWidth
              label="Qrup"
              autoComplete="off"
              disabled
              value={inputValue || ""}
              onBlur={() => formik.setFieldTouched("room", true)}
            />
            <DropdownIcon
              setOpenDropdown={setOpenDropdown}
              openDropdown={openDropdown}
            />
          </div>

          <ul
            className={`dropdown-body where-coming ${
              openDropdown ? "active" : ""
            }`}
          >
            {groupData.map((item) => (
              <li key={item._id} onClick={() => addData(item)}>
                <h4>{item.name}</h4>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* {sameItemErrMessage && (
        <small className="exist-error-message">
          Bu qrup növü artıq mövcuddur.
        </small>
      )} */}

      {/* <ul className="category-list courses-li">
        <GroupInput
          updateModalState={updateModalState}
          modalData={modalData}
          formik={formik}
          setInputValue={setInputValue}
          data={selectedGroup}
          setSelectedProgram={setSelectedProgram}
        />
      </ul> */}
    </div>
  );
};

export default GroupList;
