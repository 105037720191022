import React, { useEffect } from "react";
import GlobalHead from "../../globalComponents/GlobalHead/GlobalHead";
import { useDispatch, useSelector } from "react-redux";
import {
  TRAINING_MODAL_ACTION_TYPE,
  TRAINING_PAGINATION_ACTION_TYPE,
} from "../../redux/actions-type";
import { getAllTrainingAction, getTrainingPaginationAction } from "../../redux/actions/trainingPaginationAction";
import TrainingData from "./components/TrainingData";

const TrainingsPage = () => {
  const dispatch = useDispatch();
  const { trainings, totalLength, loading } = useSelector(
    (state) => state.trainingPagination
  );
  const { trainingSearchValues } = useSelector((state) => state.searchValues);

  const openModal = () => {
    dispatch({
      type: TRAINING_MODAL_ACTION_TYPE.GET_TRAINING_MODAL,
      payload: { data: {}, openModal: true },
    });
  };

  const getNextTraining = () => {
    if (loading) return;
    if (trainingSearchValues) {
      dispatch(
        getTrainingPaginationAction(
          trainings?.length || 0,
          trainingSearchValues
        )
      );
    } else {
      dispatch(getTrainingPaginationAction(trainings?.length || 0, ""));
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    dispatch({
      type: TRAINING_PAGINATION_ACTION_TYPE.RESET_TRAINING_PAGINATION,
    });

    dispatch(getTrainingPaginationAction(0, trainingSearchValues));
  };


  useEffect(() => {
    if (trainingSearchValues) {
      dispatch(getTrainingPaginationAction(0, trainingSearchValues));
    } else {
      dispatch(getTrainingPaginationAction(0, ""));
    }

  

    return () => {
      dispatch({
        type: TRAINING_PAGINATION_ACTION_TYPE.RESET_TRAINING_PAGINATION,
      });
    };
  }, []);

  return (
    <div className="details-page courses ">
      <GlobalHead
        searchData={searchData}
        openModal={openModal}
        DATA_SEARCH_VALUE={"TRAINING_SEARCH_VALUE"}
        dataSearchValues={trainingSearchValues}
        profile="training"
        statusType={"training"}
        count={totalLength}
      />

      <TrainingData getNextTraining={getNextTraining} />
    </div>
  );
};

export default TrainingsPage;
