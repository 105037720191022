import React, { useState } from "react";
import UpdateDeleteModal from "../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";
import { useDispatch, useSelector } from "react-redux";
import { SALARY_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import { confirmSalaryAction } from "../../../redux/actions/salaryPaginationAction";
import LoadingBtn from "../../../globalComponents/Loading/components/LoadingBtn/LoadingBtn";
import { TextField } from "@mui/material";
import { GrUpdate } from "react-icons/gr";



const SalaryCard = ({ data, mode, cellNumber, setOpenMoreModal }) => {
  const dispatch = useDispatch();
  const { salaryModalLoading, teacherId } = useSelector(
    (state) => state.salaryModal
  );
  const [rotate,setRotate] = useState(false);

  const listData = [
    { key: "Təlim sayı", value: "" },
    {
      key: "Əməkhaqqı",
      value: data?.salary ? data?.salary : "",
    },
  ];

  const updateItem = (modalType) => {
    dispatch({
      type: SALARY_MODAL_ACTION_TYPE.GET_SALARY_MODAL,
      payload: {
        data: data,
        openModal: modalType !== "more" ? true : false,
      },
    });
  };

  const confirmSalary = (data) => {
    dispatch(confirmSalaryAction(data));
  };

  const openMoreModal = () => {
    updateItem("more");
    setOpenMoreModal(true);
  };

  const openConfirmModal = () => {
    dispatch({
      type: SALARY_MODAL_ACTION_TYPE.UPDATE_SALARY_PAYMENTS,
      payload: {
        data: data,
        openModal: false,
        openConfirmModal: true,
      },
    });
  };


  const doubleClick = () => {
    openConfirmModal();
  };
  return (
    <>
      {mode === "desktop" ? (
        <tr>
          <td>
            <div className="td-con">
              <div className="cell-number">{cellNumber}.</div>
              <div className="table-scroll-text">
                {data?.teacher.fullName || ""}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          {/* <td>
            <div className="td-con">
              <div className="table-scroll-text">{data?.unviewedCount}</div>
              <div className="right-fade"></div>
            </div>
          </td> */}
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data?.cancelledCount}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">{data?.confirmedCount}</div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">
                {data.oneTrainingSalary + " AZN"}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">
                {data.monthlySalary + " AZN"}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <div className="table-scroll-text">
                {data.monthlySalary + " AZN"}
              </div>
              <div className="right-fade"></div>
            </div>
          </td>
          <td>
            <div className="td-con">
              <TextField sx={{ "& input": {
                fontSize:"16px"
              },
              }}
              type="number"

               />
            </div>
          </td>
          <td
            className="salary-check"
            style={{ backgroundColor: data.paid ? "rgb(212, 255, 191)" : "" }}
          >
            <div className={`rotate ${rotate ? "active" : ""} `} style={{ display: "flex", justifyContent: "center" ,cursor:"pointer"}}>
                <GrUpdate onClick={()=>setRotate(true)} />
              </div>
            {/* {(salaryModalLoading && data.teacher._id == teacherId && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <LoadingBtn />
              </div>
            )) || (
              <input
                type="checkbox"
                checked={data.paid}
                onChange={() => confirmSalary({ ...data, paid: !data.paid })}
                className="salary-check-input"
              />
            )} */}

            {/* <UpdateDeleteModal
              data={data}
              openMoreModal={openMoreModal}
              updateItem={updateItem}
              profil={"salary"}
              openConfirmModal={openConfirmModal}
            /> */}
          </td>
        </tr>
      ) : (
        <div className="content-box">
          <div className="left">
            <h3>{data.fullName}</h3>
            <ul>
              {listData.map((item, index) => (
                <li key={index}>
                  <span className="type">{item.key}:</span>
                  <p>{item.value}</p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default SalaryCard;
