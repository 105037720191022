import { TextField } from "@mui/material";
import React, { useState } from "react";

const TrainingInput = ({ data, modalData, updateModalState, index }) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const addTeacher = (teacher) => {
    const newTrainings = modalData.trainings.map((training, i) =>
      i === index ? { ...training, teacher } : training
    );
    updateModalState("trainings", newTrainings);
    setOpenDropdown(false);
  };

  const shouldShrinkLabel = data?.teacher?.fullName?.length > 0;

  return (
    <li style={{ listStyle: "none",marginBottom:"20px" }}>
      <div className="top training-input ">{` ${index + 1}. ${
        data?.training?.name
      }`}</div>
      <div className="dropdown-input">
        <div className="input-box">
          <TextField
            sx={{
              "& input": { fontSize: "12px", marginRight: "32px" },
              marginTop: "16px",
            }}
            InputLabelProps={{
              style: { fontSize: "12px", color: "#3F3F3F" },
              shrink: shouldShrinkLabel,
            }}
            fullWidth
            label="Müəllimlər"
            autoComplete="off"
            type="text"
            value={data?.teacher?.fullName || ""}
            disabled
          />
          <div
            className="dropdown-icon"
            onClick={() => setOpenDropdown(!openDropdown)}
          >
            <svg
              className={!openDropdown ? "down" : "up"}
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.92 9.4502L13.4 15.9702C12.63 16.7402 11.37 16.7402 10.6 15.9702L4.07999 9.4502"
                stroke="#5D5D5D"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>

        <ul className={`dropdown-body ${openDropdown ? "active" : ""}`}>
          {data?.training?.teachers?.map((teacher, i) => (
            <li key={i} onClick={() => addTeacher(teacher)}>
              <h4>{teacher.fullName}</h4>
            </li>
          ))}
        </ul>
      </div>
    </li>
  );
};

export default TrainingInput;
