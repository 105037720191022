import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import SmallLoading from "../../../globalComponents/Loading/components/SmallLoading/SmallLoading";
import { useSelector } from "react-redux";
import SalaryCard from "./SalaryCard";
import MoreModal from "../../../globalComponents/MoreModal/MoreModal";
import ConfirmModal from "../../../globalComponents/ConfirmModal/ConfirmModal";

const SalaryData = ({ getNextSalaries, salaryData }) => {
  const tableHead = [
    "Müəllim adı",
    // "Gözləmədə olan təlim sayı",
    "Ləğv edilmiş təlim sayı",
    "Keçirilmiş təlim sayı",
    "Bir təlimin əməkhaqqı",
    "Aylıq əməkhaqqı",
    "Qalıq",
    "Ödənilmiş əməkhaqqı",
    "",
  ];

  const { hasMore } = useSelector((state) => state.salaryPagination);
  const [openMoreModal, setOpenMoreModal] = useState(false);

  const { salaryConfirmModal } = useSelector((state) => state.salaryModal);

  console.log(salaryConfirmModal, "open confirm modal");
  const [scrollHeight, setScrollHeight] = useState(1);

  useEffect(() => {
    const mainHeader = document.querySelector(".main-header");
    const detailsHeader = document.querySelector(".details-header");

    const handleResize = () => {
      setScrollHeight(
        window.innerHeight -
          mainHeader.offsetHeight -
          detailsHeader.offsetHeight
      );
    };

    setScrollHeight(
      window.innerHeight - mainHeader.offsetHeight - detailsHeader.offsetHeight
    );

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {openMoreModal && (
        <MoreModal
          data={salaryData}
          setOpenMoreModal={setOpenMoreModal}
          type="salary"
        />
      )}
      {salaryConfirmModal && <ConfirmModal type="salary" />}

      <InfiniteScroll
        style={{ overflowX: "none" }}
        dataLength={salaryData.length}
        next={getNextSalaries}
        hasMore={hasMore}
        loader={<SmallLoading />}
        endMessage={<p style={{ textAlign: "center", fontSize: "20px" }}></p>}
        height={scrollHeight}
        scrollThreshold={0.7}
        className="infinity-scroll"
      >
        <table className="details-table salary-table">
          <thead>
            <tr>
              {tableHead.map((head, i) => (
                <th key={i}>{head}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {salaryData?.map((salary, i) => (
              <SalaryCard
                key={i}
                data={salary}
                mode="desktop"
                cellNumber={i + 1}
                setOpenMoreModal={setOpenMoreModal}
              />
            ))}
          </tbody>
        </table>
      </InfiniteScroll>

      <div className="details-list-tablet with-more">
        <InfiniteScroll
          style={{ overflowX: "none" }}
          dataLength={salaryData.length}
          next={getNextSalaries}
          hasMore={hasMore}
          loader={<SmallLoading />}
          endMessage={<p style={{ textAlign: "center", fontSize: "20px" }}></p>}
          height={scrollHeight}
          scrollThreshold={0.7}
        >
          {salaryData?.map((salary, i) => (
            <SalaryCard
              key={i}
              data={salary}
              mode="tablet"
              cellNumber={i + 1}
              setOpenMoreModal={setOpenMoreModal}
            />
          ))}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default SalaryData;
