import { Box, TextField } from "@mui/material";
import { AiOutlinePlusCircle } from "react-icons/ai";
import React, { useEffect, useState } from "react";
import moment from "moment";
import "moment/locale/az";
import { useDispatch, useSelector } from "react-redux";
import { SALARY_MODAL_ACTION_TYPE } from "../../../../redux/actions-type";
import Paids from "./components/Paids";
import LoadingBtn from "../../../Loading/components/LoadingBtn/LoadingBtn";
import { updateSalaryAction } from "../../../../redux/actions/salaryPaginationAction";

const SalaryConfirmModal = () => {
  const { salaryModalData,salaryModalLoading } = useSelector(
    (state) => state.salaryModal
  );
  const dispatch = useDispatch();
  const [paidData, setPaidData] = useState({
    payment: "",
    paymentDate: "",
  });

  const updateSalaryPayments = () => {
    dispatch(
      updateSalaryAction(salaryModalData._id, salaryModalData)
    );
  };

  const confirmHead = [
    { id: 1, name: "Tarix" },
    { id: 2, name: "Status" },
    { id: 3, name: "Qalıq" },
    { id: 4, name: "Məbləğ" },
  ];

  const togglePaymentStatus = (data) => {
    const newPayments = salaryModalData?.payments.map((item) =>
      item._id === data._id ? data : item
    );

    dispatch({
      type: SALARY_MODAL_ACTION_TYPE.UPDATE_SALARY_PAYMENTS,
      payload: {
        data: { ...salaryModalData, payments: newPayments },
        openModal: false,
        openConfirmModal: "openConfirmModal",
      },
    });
  };

  const addPayment = () => {
    const checkPaids = Array.isArray(salaryModalData.paids);
    dispatch({
      type: SALARY_MODAL_ACTION_TYPE.UPDATE_SALARY_PAYMENTS,
      payload: {
        data: {
          ...salaryModalData,
          paids: checkPaids
            ? [...salaryModalData.paids, paidData]
            : [paidData],
        },
        openModal: false,
        openConfirmModal: "openConfirmModal",
      },
    });

    setPaidData({
      payment: "",
      paymentDate: "",
    });
  };

  const confirmInformationData = [
    {
      id: 1,
      title: "Yekun məbləğ:",
      value: `${salaryModalData?.totalAmount} AZN`,
    },
    {
      id: 2,
      title: "Ödənilən məbləğ:",
      value: `${salaryModalData?.totalPaids} AZN`,
    },
    {
      id: 3,
      title: "Qalıq:",
      value: `${salaryModalData?.totalRest} AZN`,
    },
    {
      id: 4,
      title: "Cari ödəniş:",
      value: `${salaryModalData?.currentPayment}AZN`,
    },
  ];


  return (
    <div style={{ marginTop: "30px" }}>
      {confirmInformationData?.map((data) => (
        <div className="confirm-information-container" key={data.id}>
          <h2>{data.title}</h2>
          <h2>{data.value}</h2>
        </div>
      ))}

      <h2 style={{ marginTop: "20px" }}>Ödəniş cədvəli:</h2>
      {salaryModalData?.paymentsTable?.length ? (
        <table className="confirm-table">
          <thead>
            <tr>
              {confirmHead.map((item) => (
                <th key={item.id}>{item.name}</th>
              ))}
            </tr>
          </thead>
          <tbody style={{ width: "100%" }}>
            {salaryModalData?.paymentsTable?.map((item) => (
              <tr
                className={`payment-container ${
                  item.status === "paid"
                    ? "payment-green"
                    : item.status === "not-paid"
                    ? "payment-red"
                    : ""
                }`}
                key={item._id}
              >
                <td>
                  <h2>
                    {item?.paymentDate
                      ? moment(item.paymentDate)
                          .locale("az")
                          .format("DD.MM.YYYY")
                      : ""}
                  </h2>
                </td>
                <td>
                  <h2>{item?.paid ? "ödənildi" : "ödənilməyib"}</h2>
                </td>
                <td>
                  <h2>{item.rest} AZN</h2>
                </td>
                <td>
                  <h2>{item.payment} AZN</h2>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : null}

      <div style={{ marginTop: "40px" }} className="tution-fee-confirm-modal">
        <h2 style={{ marginBottom: "40px" }}>Ödənişlər:</h2>
        <Paids salaryModalData={salaryModalData} />
        <div>
          <Box>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "2fr 2fr 1fr",
                columnGap: "10px",
              }}
            >
              <TextField
                sx={{
                  "& input": {
                    fontSize: "12px",
                  },
                  marginTop: "0",
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: "12px",
                    color: "#3F3F3F",
                    marginBottom: "10px",
                  },
                }}
                fullWidth
                id={"payment"}
                name={"payment"}
                type="number"
                label="ödəniş"
                value={paidData.payment}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setPaidData({ ...paidData, payment: e.target.value });
                }}
                // onFocus={() => setShrink(true)}
              />
              <TextField
                sx={{
                  "& input": {
                    fontSize: "12px",
                  },
                  marginTop: "0",
                }}
                InputLabelProps={{
                  shrink: true,
                  style: {
                    fontSize: "12px",
                    color: "#3F3F3F",
                    marginBottom: "10px",
                  },
                }}
                fullWidth
                id={"paymentDate"}
                name={"paymentDate"}
                type="date"
                label="tarix"
                value={paidData.paymentDate}
                onWheel={(e) => e.target.blur()}
                onChange={(e) => {
                  setPaidData({ ...paidData, paymentDate: e.target.value });
                }}
                // onFocus={() => setShrink(true)}
              />
              <div className="right">
                <button
                  disabled={!paidData.payment || !paidData.paymentDate}
                  onClick={addPayment}
                  className="add-class"
                >
                  <AiOutlinePlusCircle />
                </button>
              </div>
            </div>
          </Box>
        </div>

        <div className="create-update-modal-btn">
          <button
            disabled={
              salaryModalLoading || paidData.payment || paidData.paymentDate
            }
            onClick={updateSalaryPayments}
          >
            {salaryModalLoading ? <LoadingBtn /> : "Təstiqlə"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SalaryConfirmModal;
