import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import SmallLoading from "../../../globalComponents/Loading/components/SmallLoading/SmallLoading";
import TrainingCard from "./TrainingCard";

const TrainingData = ({ getNextTraining }) => {
  const { hasMore, trainings } = useSelector(
    (state) => state.trainingPagination
  );
  const [scrollHeight, setScrollHeight] = useState(1);

  const tableHead = [
    { id: 1, label: "Təlim adı" },
    { id: 2, label: "Müəllimlər" },
    { id: 3, label: "" },
  ];

  useEffect(() => {
    const mainHeader = document.querySelector(".main-header");
    const detailsHeader = document.querySelector(".details-header");

    const handleResize = () => {
      setScrollHeight(
        window.innerHeight -
          mainHeader.offsetHeight -
          detailsHeader.offsetHeight
      );
    };

    setScrollHeight(
      window.innerHeight - mainHeader.offsetHeight - detailsHeader.offsetHeight
    );

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <InfiniteScroll
        style={{ overflowX: "none" }}
        dataLength={trainings.length}
        next={getNextTraining}
        hasMore={hasMore}
        loader={<SmallLoading />}
        endMessage={<p style={{ textAlign: "center", fontSize: "20px" }}></p>}
        scrollThreshold={0.7}
        height={scrollHeight}
      >
        <table className="details-table">
          <thead>
            <tr>
              {tableHead.map((head, i) => (
                <th key={i}>{head.label}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            {trainings?.map((training, i) => (
              <TrainingCard
                key={training._id}
                data={training}
                mode="desktop"
                cellNumber={i + 1}
              />
            ))}
          </tbody>
        </table>
      </InfiniteScroll>
    </>
  );
};

export default TrainingData;
