import { DELETE_MODAL_ACTION_TYPE } from "../actions-type";

const initialState = {
  deleteModalLoading: false,
};

export const deleteModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_MODAL_ACTION_TYPE.DELETE_MODAL_LOADING:
      return {
        ...state,
        deleteModalLoading: action.payload,
      };
    default:
      return state;
  }
};
