import { TRAINING_MODAL_ACTION_TYPE } from "../actions-type";

const initialState = {
  trainingModalData: {
    name: "",
  },
  trainingModalLoading: false,
  trainingOpenModal: false,
};

export const trainingsModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRAINING_MODAL_ACTION_TYPE.GET_TRAINING_MODAL:
      return {
        ...state,
        trainingModalData: action.payload.data,
        trainingOpenModal: action.payload.openModal,
      };
    case TRAINING_MODAL_ACTION_TYPE.TRAINING_OPEN_MODAL:
      return {
        ...state,
        trainingOpenModal: action.payload,
      };
    case TRAINING_MODAL_ACTION_TYPE.TRAINING_MODAL_LOADING:
      return {
        ...state,
        trainingModalLoading: action.payload,
      };
    default:
      return state;
  }
};
