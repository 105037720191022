import { TRAINING_PAGINATION_ACTION_TYPE } from "../actions-type";

const initialState = {
  trainings: [],
  allTrainings: [],
  hasMore: true,
  loading: false,
  totalLength: 0,
};

export const trainingPaginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case TRAINING_PAGINATION_ACTION_TYPE.GET_ALL_TRAINING:
      return {
        ...state,
        allTrainings: action.payload,
      };
    case TRAINING_PAGINATION_ACTION_TYPE.GET_TRAINING_PAGINATION:
      return {
        ...state,
        trainings: [...state.trainings, ...action.payload.trainings],
        totalLength: action.payload.totalLength,
        hasMore: !(action.payload.trainings.length < 20),
      };
    case TRAINING_PAGINATION_ACTION_TYPE.CREATE_TRAINING:
      return {
        ...state,
        trainings: [...state.trainings, action.payload],
        totalLength: state.totalLength + 1,
      };
    case TRAINING_PAGINATION_ACTION_TYPE.UPDATE_TRAINING:
      return {
        ...state,
        trainings: state.trainings.map((training) =>
          training._id === action.payload._id ? action.payload : training
        ),
      };

    case TRAINING_PAGINATION_ACTION_TYPE.DELETE_TRAINING:
      return {
        ...state,
        trainings: state.trainings.filter(
          (training) => training._id !== action.payload
        ),
        totalLength: state.totalLength - 1,
      };
    case TRAINING_PAGINATION_ACTION_TYPE.TRAINING_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case TRAINING_PAGINATION_ACTION_TYPE.RESET_TRAINING_PAGINATION:
      return {
        ...state,
        trainings: [],
        hasMore: true,
        totalLength: 0,
      };

    default:
      return state;
  }
};
