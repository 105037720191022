import * as yup from "yup";

export const ValidationSchema = yup.object().shape({
  fullName: yup
    .string()
    .min(3, "Mininum 3 hərfdən ibarət olmalıdır.")
    .required("Bu xana tələb olunur."),
  email: yup
    .string()
    .email("Email düzgün deyil.")
    .required("Bu xana tələb olunur."),
  password: yup.string().min(6, "Mininum 6 simvoldan ibarət olmalıdır."),
});
