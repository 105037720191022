import React, { useEffect, useState } from "react";
import GlobalHead from "../../globalComponents/GlobalHead/GlobalHead";
import SalaryData from "./components/SalaryData";
import { useDispatch, useSelector } from "react-redux";
import {
  DATEPICKER_ACTION_TYPE,
  SALARY_PAGINATION_ACTION_TYPE,
  SEARCH_VALUES_ACTION_TYPES,
} from "../../redux/actions-type";
import { getSalaryPaginationAction } from "../../redux/actions/salaryPaginationAction";

const SalaryPage = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(false);
  const { totalLength, loading, salaries } = useSelector(
    (state) => state.salaryPagination
  );
  const { startDate } = useSelector((state) => state.datepicker);
  const { endDate } = useSelector((state) => state.datepicker);
  const { salariesSearchValues } = useSelector((state) => state.searchValues);

  const searchData = (e) => {
    e.preventDefault();
    dispatch({ type: SALARY_PAGINATION_ACTION_TYPE.RESET_SALARY_PAGINATION });
    dispatch(getSalaryPaginationAction(0, salariesSearchValues));
  };

  const getNextSalaries = () => {
    if (loading) return;

    if (salariesSearchValues) {
      dispatch(
        getSalaryPaginationAction(
          salaries?.length || 0,
          salariesSearchValues,
          startDate ? startDate : "",
          endDate ? endDate : ""
        )
      );
    } else {
      dispatch(
        getSalaryPaginationAction(
          salaries?.length || 0,
          "",
          startDate ? startDate : "",
          endDate ? endDate : ""
        )
      );
    }
  };

  const clearAll = () => {
    // dispatch(clearLessonsFilter());
    dispatch({
      type: DATEPICKER_ACTION_TYPE.START_DATE,
      payload: new Date(),
    });
    dispatch({ type: SALARY_PAGINATION_ACTION_TYPE.RESET_SALARY_PAGINATION });

    dispatch(getSalaryPaginationAction(0, "", "", ""));
    dispatch({
      type: SEARCH_VALUES_ACTION_TYPES.SALARIES_SEARCH_VALUE,
      payload: "",
    });
  };
  const applySalaryFilter = () => {
    dispatch({ type: SALARY_PAGINATION_ACTION_TYPE.RESET_SALARY_PAGINATION });

    if (salariesSearchValues) {
      dispatch(
        getSalaryPaginationAction(0, salariesSearchValues, startDate, endDate)
      );
    } else {
      dispatch(getSalaryPaginationAction(0, "", startDate, endDate));
    }

    setFilter(false);
  };

  useEffect(() => {
    if (salariesSearchValues) {
      dispatch(getSalaryPaginationAction(0, salariesSearchValues, "", ""));
    } else {
      dispatch(getSalaryPaginationAction(0, "", "", ""));
    }
    return () => {
      dispatch({ type: SALARY_PAGINATION_ACTION_TYPE.RESET_SALARY_PAGINATION });
    };
  }, []);

  return (
    <div className="details-page salary-page ">
      <GlobalHead
        searchData={searchData}
        DATA_SEARCH_VALUE={"SALARIES_SEARCH_VALUE"}
        dataSearchValues={salariesSearchValues}
        statusType="salary"
        profile={"salary"}
        count={totalLength}
        clearAll={clearAll}
        applyFilter={applySalaryFilter}
      />

      <SalaryData salaryData={salaries} getNextSalaries={getNextSalaries} />
    </div>
  );
};

export default SalaryPage;
