import React, { useEffect } from "react";
import { ReactComponent as MinusIcon } from "../../../../../assets/icons/minus-cirlce.svg";
import PaymentType from "../Groups/components/PaymentType";
import Payments from "./components/Payments/Payments";
import InputField from "./components/Inputs/InputField";
import DiscountReason from "./components/DiscountReason/DiscountReason";
import Status from "./components/Status/Status";

const GroupInput = ({
  data,
  // index,  // Removed the index prop
  // deleteItem,
  modalData,
  updateModalState,
  formik,
  setInputValue,
  setSelectedProgram,
}) => {
  const groupData = data; // Use the passed data directly

  const addPaymentType = (item) => {
    groupData.payment = item;
    updateModalState("program", groupData);
    setSelectedProgram(groupData);
  };

  const addPayments = (key, value, index) => {
    groupData.payments[index] = {
      ...groupData.payments[index],
      [key]: value,
    };

    updateModalState("program", groupData);
    setSelectedProgram(groupData);
  };

  const addGroupData = (key, value) => {
    groupData[key] = value;

    updateModalState("program", groupData);
    setSelectedProgram(groupData);
  };
  useEffect(() => {
    if (data.amount && data.paymentPart) {
      const amount = data.amount;
      const discount = data?.discount || 0;

      const totalAmount = amount - (amount * discount) / 100;
      const part = data.paymentPart;
      const onePartPayment = totalAmount / part;

      const paymentArr = [];

      for (let i = 0; i < part; i++) {
        paymentArr.push({
          payment: onePartPayment.toFixed(2),
          paymentDate: "",
          status: "wait",
        });
      }

      const updatedGroupData = {
        ...groupData,
        payments: paymentArr,
        totalAmount: totalAmount,
      };

      updateModalState("program", updatedGroupData);
      setSelectedProgram(updatedGroupData);
    }
  }, [data.discount, data.amount, data.paymentPart]);

  return (
    <li className="group-li">
      {/* <div className="top">
        {data?.group?.name}, {data?.group?.course?.name}
        <div className="minus-icon-con">
          <MinusIcon
            className="minus-icon"
            onClick={() => deleteItem(data.group._id)}
          />
        </div>
      </div> */}

      {/* <InputField
        inputName={"degree"}
        formik={formik}
        setInputValue={setInputValue}
        data={data}
        addGroupData={addGroupData}
      /> */}

      <div className="input-couples">
        <InputField
          inputName={"contractStartDate"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        />
        <InputField
          inputName={"contractEndDate"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        />
        {/* <InputField
          inputName={"paymentStartDate"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        /> */}
        {/* <InputField
          inputName={"contractId"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        /> */}
      </div>
      {/* <PaymentType
        data={data}
        addPaymentType={addPaymentType}
        formik={formik}
      /> */}
      <div className="input-couples">
        <DiscountReason
          data={data}
          addGroupData={addGroupData}
          formik={formik}
        />
        <InputField
          inputName={"discount"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        />
        <InputField
          inputName={"amount"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        />
        <InputField
          inputName={"totalAmount"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        />
        <InputField
          inputName={"paymentPart"}
          formik={formik}
          setInputValue={setInputValue}
          data={data}
          addGroupData={addGroupData}
        />
      </div>

      {/* <h1 style={{ marginTop: "20px", fontSize: "40px" }}>Ödənişlər</h1> */}
      {data?.payments?.map((item, index) => (
        <Payments
          key={index}
          index={index}
          setInputValue={setInputValue}
          data={item}
          addPayments={addPayments}
          formik={formik}
        />
      ))}
    </li>
  );
};

export default GroupInput;
