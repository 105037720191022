import axios from "axios";

import { toast } from "react-toastify";
import { logoutAction } from "./auth";
import { apiRoot } from "../../apiRoot";
import {
  TRAINING_MODAL_ACTION_TYPE,
  TRAINING_PAGINATION_ACTION_TYPE,
} from "../actions-type";

const API = axios.create({
  baseURL: `${apiRoot}/training`,
  withCredentials: true,
});
API.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).AccessToken
    }`;
  }

  return req;
});

const refreshApi = axios.create({
  baseURL: `${apiRoot}/user/auth/refresh_token`,
  withCredentials: true,
});

const toastSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};
const toastError = (message) => {
  toast.error(message, {
    position: "top-right",
    autoClose: 2000,
    toastClassName: "custom-toast",
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

const setLoadingTrainingAction = (loadingValue) => ({
  type: TRAINING_PAGINATION_ACTION_TYPE.TRAINING_LOADING,
  payload: loadingValue,
});
const modalLoading = (loadingValue) => ({
  type: TRAINING_MODAL_ACTION_TYPE.TRAINING_MODAL_LOADING,
  payload: loadingValue,
});

const trainingModalOpen = (value) => ({
  type: TRAINING_MODAL_ACTION_TYPE.TRAINING_OPEN_MODAL,
  payload: value,
});

export const getAllTrainingAction = () => async (dispatch) => {
  try {
    const { data } = await API.get("/all");
    dispatch({
      type: TRAINING_PAGINATION_ACTION_TYPE.GET_ALL_TRAINING,
      payload: data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getTrainingPaginationAction =
  (length, searchQuery) => async (dispatch) => {
    dispatch(setLoadingTrainingAction(true));
    try {
      const { data } = await API.get(
        `/pagination/?length=${length || 0}&searchQuery=${searchQuery}`
      );
      dispatch({
        type: TRAINING_PAGINATION_ACTION_TYPE.GET_TRAINING_PAGINATION,
        payload: data,
      });
    } catch (error) {
      console.log(error);
      const originalRequest = error.config;
      if (error?.response?.status === 403 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const token = await refreshApi.get("/");
          localStorage.setItem(
            "auth",
            JSON.stringify({
              AccessToken: token.data.accesstoken,
            })
          );
          const { data } = await API.get(
            `/pagination/?length=${length || 0}&searchQuery=${searchQuery}`
          );
          dispatch({
            type: TRAINING_PAGINATION_ACTION_TYPE.GET_TRAINING_PAGINATION,
            payload: data,
          });
        } catch (error) {
          if (error?.response?.status === 401) {
            return dispatch(logoutAction());
          }
        }
      }
      if (error?.response?.status === 403) {
        dispatch(logoutAction());
      }
    } finally {
      dispatch(setLoadingTrainingAction(false));
    }
  };

export const createTrainingAction = (newData) => async (dispatch) => {
  dispatch(modalLoading(true));
  try {
    const { data } = await API.post("/", newData);
    dispatch({
      type: TRAINING_PAGINATION_ACTION_TYPE.CREATE_TRAINING,
      payload: data,
    });
    dispatch(trainingModalOpen(false));
    toastSuccess("Yeni təlim yaradıldı");
  } catch (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await API.post("/", newData);
        dispatch({
          type: TRAINING_PAGINATION_ACTION_TYPE.CREATE_TRAINING,
          payload: data,
        });
        dispatch(trainingModalOpen(false));
        toastSuccess("Yeni təlim yaradıldı");
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
      }
    }
    if (error?.response?.status === 403) {
      dispatch(logoutAction());
    }
    // console.log(error);
  } finally {
    dispatch(modalLoading(false));
  }
};

export const updateTrainingAction = (_id, newData) => async (dispatch) => {
  dispatch(modalLoading(true));
  try {
    const { data } = await API.patch(`/${_id}`, newData);
    dispatch({
      type: TRAINING_PAGINATION_ACTION_TYPE.UPDATE_TRAINING,
      payload: data,
    });
    dispatch(trainingModalOpen(false));
    toastSuccess("Təlim yeniləndi.");
  } catch (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await API.patch(`/${_id}`, newData);
        dispatch({
          type: TRAINING_PAGINATION_ACTION_TYPE.UPDATE_TRAINING,
          payload: data,
        });
        dispatch(trainingModalOpen(false));
        toastSuccess("Təlim yeniləndi.");
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
      }
    }
    // console.log(error);
    toastError(error?.response?.data?.message);
  } finally {
    dispatch(modalLoading(false));
  }
};

export const deleteTrainingAction = (_id) => async (dispatch) => {
  try {
    const { data } = await API.delete(`/${_id}`);
    dispatch({
      type: TRAINING_PAGINATION_ACTION_TYPE.DELETE_TRAINING,
      payload: data._id,
    });
    toastSuccess("Təlim silindi");
  } catch (error) {
    const originalRequest = error.config;
    if (error?.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const token = await refreshApi.get("/");
        localStorage.setItem(
          "auth",
          JSON.stringify({
            AccessToken: token.data.accesstoken,
          })
        );
        const { data } = await API.delete(`/${_id}`);
        dispatch({
          type: TRAINING_PAGINATION_ACTION_TYPE.DELETE_TRAINING,
          payload: data._id,
        });
        toastSuccess("Təlim silindi");
      } catch (error) {
        if (error?.response?.status === 401) {
          return dispatch(logoutAction());
        }
      }
    } else if (error?.response?.data?.key === "exist-syllabus") {
      toastError("Bu təlim sillabusda mövcuddur!");
    }
  }
};
