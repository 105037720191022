import { SALARY_PAGINATION_ACTION_TYPE } from "../actions-type";

const initialState = {
  salaries: [],
  loading: false,
  totalLength: 0,
  hasMore: true,
};

export const salaryPaginationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SALARY_PAGINATION_ACTION_TYPE.GET_SALARY_PAGINATION:
      return {
        ...state,
        salaries: [...state.salaries, ...action.payload.salaries],
        totalLength: action.payload.totalLength,
        hasMore: !(action.payload.salaries.length < 20),
      };
    case SALARY_PAGINATION_ACTION_TYPE.SALARY_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case SALARY_PAGINATION_ACTION_TYPE.RESET_SALARY_PAGINATION:
      return {
        ...state,
        salaries: [],
        totalLength: 0,
        hasMore: true,
      };
    case SALARY_PAGINATION_ACTION_TYPE.CONFIRM_SALARY:
      return {
        ...state,
        salaries: state.salaries.map((item) =>
          item.teacher._id.toString() === action.payload._id.toString()
            ? action.payload
            : item
        ),
      };
    default:
      return state;
  }
};
